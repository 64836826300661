import { Particle, World } from "baby-verlet";
import TetheredElement from "./TetheredElement";
import Scene from "./Scene";

var world = new World({
  width: window.innerWidth,
  height: window.innerHeight,
  gravity: [0, 0],
  airFriction: 0.02
});

var scene = new Scene({
  physics: world
});

var mouseParticle = new Particle({
  position: [9999, 9999],
  fixed: true
});
world.add(mouseParticle);
$(window).on("mousemove", function(e) {
  mouseParticle.moveTo(e.pageX, e.pageY);
});

$(".app-stub").each(function() {
  if (this.dataset.app === "colorchat") {
    var el = $(this).find(".app-stub-icon")[0];
    // addTetheredElement(el);
    $(this)
      .find(".colorchat-icon-letter")
      .each(function() {
        var hue = Math.round(Math.random() * 360);
        this.style.color = "hsl(" + hue + ",100%,65%)";
        // addTetheredElement(this);
      });
  }
});

var taglineTimeout = undefined;
$(".app-stub").on("mouseenter", function() {
  if (!scene.running) return;
  if (taglineTimeout) clearTimeout(taglineTimeout);

  $(".tagline-text").text($(this).data("tagline"));
  $(".tagline").addClass("tagline-active-pre");
  taglineTimeout = setTimeout(function() {
    $(".tagline").addClass("tagline-active");
  }, 0);
});

$(".app-stub").on("mouseleave", function() {
  if (!scene.running) return;
  if (taglineTimeout) clearTimeout(taglineTimeout);

  $(".tagline").removeClass("tagline-active");
  taglineTimeout = setTimeout(function() {
    $(".tagline").removeClass("tagline-active-pre");
  }, 1000);
});

function addTetheredElement(el) {
  var baseOpts = {
    physics: world,
    particles: { mouse: mouseParticle }
  };

  scene.add(new TetheredElement({ ...baseOpts, el }));
}

function setState() {
  var w = $(window).width();
  if (scene.running && w <= 1024) {
    scene.stop();
    scene.reset();
  } else if (!scene.running && w > 1024) {
    scene.start();
  }
}

console.log("gonna set");
setState();
$(window).resize(setState);
