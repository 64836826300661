class Scene {
  constructor(ref) {
    const physics = ref.physics;
    const elements = ref.elements === undefined ? [] : ref.elements;

    this.physics = physics;
    this.elements = elements;
    this.running = false;
  }

  add(element) {
    this.elements.push(element);
  }

  start() {
    this.running = true;
    this.run();
  }

  stop() {
    this.running = false;
  }

  run() {
    var _this = this;

    if (!this.running) return;
    this.physics.update();
    this.elements.forEach(function(e) {
      return e.update();
    });
    requestAnimationFrame(function() {
      return _this.run();
    });
  }

  reset() {
    this.elements.forEach(function(e) {
      return e.reset();
    });
  }
}

export default Scene;
